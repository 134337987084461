import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormControl } from '@angular/forms';
import { L10nTranslationService } from 'angular-l10n';

@Injectable()
export class UtilsService {

  public cifPattern = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
  public DNI_REGEX = /^(\d{8})([A-Z])$/;
  public NIE_REGEX = '/^[XYZ]\d{7,8}[A-Z]$/';
  public emailOrNickPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$|^[a-zA-Z0-9_-]{5,15}$/;
  public emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  public unamePattern = '^[a-zA-Z0-9_-]{5,15}$';

  public phonePattern = /^(\+[\d]{1,5}|0)?[0-9]\d{7,15}$/;
  constructor(public translation: L10nTranslationService) {
  }

  backgroundImage(img: string, defaultAvatarImage?: boolean): { 'background-image': string } {
    if (!img && defaultAvatarImage) {
      img = '/assets/images/avatar-small.svg';
    }
    return { 'background-image': `url('${img}')` };
  }

  compareById(a: { id: any }, b: { id: any }): boolean { // We do not have a BaseEntity to use it instead of "any"
    return a.id === b.id;
  }
  roleComparator(a: { id: any }, b: { id: any }): boolean { // We do not have a BaseEntity to use it instead of "any"
    var ac = a.id ?? a;
    var bc = b.id ?? b;
    return ac === bc;
  }

  getHour(d: Date): string {
    return new DatePipe('en-GB').transform(d, 'HH:mm');
  }

  getInitials(fullName: string): string {
    let result = '';
    if (fullName) {
      const words = fullName.toUpperCase().split(' ');
      let i = 0;
      while (i < 2 && i < words.length) {
        result += words[i++].charAt(0);
      }
    }
    return result;
  }

  toLowerCase(value: string): string {
    return value ? value.toLowerCase() : null;
  }

  validateForm(form: FormGroup): void {
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

  enumValues(anEnum: any): number[] {
    return Object.keys(anEnum).filter(key => typeof anEnum[key] === 'number').map(k => anEnum[k] as number);
  }

  enumNames(anEnum: any): string[] {
    return Object.keys(anEnum).filter(key => typeof anEnum[key] === 'string').map(k => anEnum[k] as string);
  }

}

