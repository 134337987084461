import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthHttpInterceptor } from 'src/utilities/services/auth-http-interceptor.service';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './store/reducers';
import { StoreRouterConnectingModule, RouterState } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { TitleUpdaterEffects } from './store/effects/title-updater.effects';
import { CommonModule } from '@angular/common';
import { L10nIntlModule, L10nLoader, L10nTranslationModule } from 'angular-l10n';
import { HttpTranslationLoader, getSpanishPaginatorIntl, l10nConfig } from 'src/utilities/l10n/l10n-config';
import { SessionModule } from 'src/utilities/session/session.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { SpinnerComponent } from './spinner/spinner.component';
import { LoadingInterceptor } from './loading.interceptor';
import { FileComparatorComponent } from './fileComparator/file-comparator.component';

export function initL10n(l10nLoader: L10nLoader): () => Promise<void> {
  return () => l10nLoader.init();
}

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent
    ],
  imports: [
    CommonModule,
    L10nTranslationModule.forRoot(l10nConfig, {
      translationLoader: HttpTranslationLoader
    }),
    L10nIntlModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    SessionModule,
    SharedModule.forRoot(),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([TitleUpdaterEffects]),
    StoreRouterConnectingModule.forRoot({ routerState: RouterState.Minimal }),
    BrowserAnimationsModule,
    NoopAnimationsModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: initL10n, deps: [L10nLoader], multi: true },
    { provide: MatPaginatorIntl, useValue: getSpanishPaginatorIntl() },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})



export class AppModule { }
